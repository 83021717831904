import React from 'react'
import '../Footer/footer.css'
const Footer = () => {
  return (
    <footer className='footer'>
      Copyright © 2024
    </footer>

  );
}

export default Footer